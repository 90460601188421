document.addEventListener('DOMContentLoaded', function() {
    let trigger = document.querySelectorAll('.selected-item');
    if (trigger.length) {
        trigger.forEach( el => {
          el.addEventListener('click', () => {
            document.documentElement.classList.toggle('is-clipped');
            el.classList.toggle('opened');
            document.querySelector('[data-dropdown="menu-categories-dropdown"]').classList.toggle('is-active');
          });
        });
    }

    let dropdownItems = document.querySelectorAll('.dropdown li');
    dropdownItems.forEach( el => {

      el.addEventListener('click', () => {
        document.querySelector('.dropdown li.is-chosen').classList.remove('is-chosen');
        document.querySelector('[data-dropdown="menu-categories-dropdown"]').classList.toggle('is-active');
        document.querySelector('.selected-item').classList.toggle('opened');
        el.classList.add('is-chosen');
        document.documentElement.classList.toggle('is-clipped');
        var scrollDiv = document.getElementById(el.dataset.target).offsetTop;
        window.scrollTo({ top: scrollDiv + 160, behavior: 'smooth'});



      });
    })
});
